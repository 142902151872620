































































import {Component, Vue} from "vue-property-decorator";
import RequestW8BENModal from "@/components/archive/w8ben/RequestW8BENModal.vue";
import {namespace} from "vuex-class";
import ErrorLocalePathUtils from "@/utils/ErrorLocalePathUtils";
import FillableDocumentFilter from "@/dto/archive/FillableDocumentFilter";
import {FillableDocumentType} from "@/dto/archive/FillableDocumentType";
import DocumentService from "@/services/DocumentService";
import FillableDocumentDataDTO from "@/dto/archive/FillableDocumentDataDTO";
import Workspaces from "@/state/Workspaces";
import RouteNames from "@/router/RouteNames";
import {FillableDocumentStatus} from "@/dto/archive/FillableDocumentStatus";

const AppModule = namespace("App");
const Auth = namespace("Auth");

@Component
export default class W8BENList extends Vue {

    private successful = false;

    private message = "";

    @AppModule.State
    private loading!: boolean;

    @AppModule.Action
    private startLoading!: () => void;

    @AppModule.Action
    private stopLoading!: () => void;

    private forms: Array<FillableDocumentDataDTO> = [];

    mounted() {
        this.loadForms();
    }

    showModal() {
        this.$modal.show(
            RequestW8BENModal,
            {
                companyId: Workspaces.getCurrent.id,
                onSaved: this.onSaved,
            }
        );
    }

    loadForms(){
        this.startLoading();
        this.message = "";
        const filter = new FillableDocumentFilter();
        filter.requestingCompanyId = Workspaces.getCurrent.id;
        filter.documentType = FillableDocumentType.W8BEN;
        return DocumentService.getFillableByFilter(filter).then(
            success => this.forms = success.data.data,
            error => this.message = this.$t(ErrorLocalePathUtils.getPathForError(error)) as string
        ).then(() => this.stopLoading());;
    }

  onFormClick(form: FillableDocumentDataDTO): void {
    if (form.status === FillableDocumentStatus.NEW || form.status === FillableDocumentStatus.BEING_FILLED_OUT) {
      this.goToForm(form.id);
    }
    if (form.status === FillableDocumentStatus.SIGNED) {
      Vue.prototype.$docUtils.download(form.signedFile!.id);
    }
  }

  private goToForm(id: number) {
    this.$router.push({name: RouteNames.ARCHIVE_W8BEN_INTERNAL, params: { id: `${id}`} });
  }

  onSaved(sublimeFilling: boolean, id: number): void {
    if (sublimeFilling) {
      this.goToForm(id);
    } else {
      this.loadForms();
    }
  }

}
