import {FillableDocumentType} from "@/dto/archive/FillableDocumentType";

export default class CreateFillableDocumentRequestDTO {

    public personId: number | null = null;

    public email: string | null = null;

    public firstName: string | null = null;

    public lastName: string | null = null;

    public documentType: FillableDocumentType | null = null;

    public requestingCompanyId: number | null = null;

    public sublimeFilling = false;

    public employmentId: number | null = null;
}
