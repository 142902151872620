




































import {Component, Prop, Vue} from "vue-property-decorator";
import CreateFillableDocumentRequestDTO from "@/dto/archive/CreateFillableDocumentRequestDTO";
import DocumentService from "@/services/DocumentService";
import {namespace} from "vuex-class";
import ErrorLocalePathUtils from "@/utils/ErrorLocalePathUtils";
import {FillableDocumentType} from "@/dto/archive/FillableDocumentType";
import PortalInput from "@/components/common/PortalInput.vue";
import {InputType} from "@/components/common/InputType";
import {ifValid, processError} from "@/utils/ComponentUtils";
import {EmploymentFilter, ForeignContractorFilter} from "@/dto/payroll/Filters";
import {CounterpartyType} from "@/constants/CounterpartyType";
import Workspaces from "@/state/Workspaces";
import {EmploymentStatus} from "@/components/payroll/business/employments/contractors/Contractor";
import Application from "@/state/Application";
import EmploymentService from "@/services/EmploymentService";
import {
	IndividualForeignContractorDTO
} from "@/components/payroll/business/employments/foreignContractors/ForeignContractorPayloads";
import PortalSelect from "@/components/common/PortalSelect.vue";
import SelectOption from "@/components/common/SelectOption";
import e from "express";

const AppModule = namespace("App");

@Component({
	computed: {
		SelectOption() {
			return SelectOption
		},
		InputType() {
			return InputType
		}
	},
	components: {PortalSelect, PortalInput}
})
export default class RequestW8BENModal extends Vue {

	private successful = false;

	private message = "";

	private employments: Array<IndividualForeignContractorDTO> = [];

	@AppModule.State
	private loading!: boolean;

	@AppModule.Action
	private startLoading!: () => void

	@AppModule.Action
	private stopLoading!: () => void

	private request = new CreateFillableDocumentRequestDTO();

	private employmentFilter: ForeignContractorFilter = new ForeignContractorFilter(
		{
			contractorType: CounterpartyType.PERSON,
			employerId: Workspaces.getCurrent.id,
			status: [EmploymentStatus.ACTIVE, EmploymentStatus.PENDING_FOR_DOCUMENTS, EmploymentStatus.NEW],
		}
	)

	@Prop()
	private companyId!: number;

  @Prop()
  private onSaved!: (sublimeFilling: boolean, id: number) => void;

	public mounted(): void {
		Application.startLoading();
		EmploymentService.getAllByFilter<IndividualForeignContractorDTO>(this.employmentFilter).then(
			(res) => {
				this.employments = res.data;
				Application.stopLoading()
			},
			err => processError(err, this)
		)
		this.request.requestingCompanyId = this.companyId;
		this.request.documentType = FillableDocumentType.W8BEN;
	}

	private applySearch() {
		let employment = this.employments.find(it => it.id == this.request.employmentId);
		if (employment) {
			this.request.firstName = employment.details.firstName;
			this.request.lastName = employment.details.lastName;
			this.request.email = employment.details.email
		}
	}

	save() {
		ifValid(this, () => {
			this.message = "";
			this.startLoading();
			DocumentService.registerW8BEN(this.request).then(
				ok => {
          this.onSaved(this.request.sublimeFilling, ok.data.id);
					this.stopLoading();
					this.$modal.hideAll();
				},
				error => processError(error, this)
			).then(() => this.stopLoading());
		})
	}

}
